.element {

  margin-bottom: 12rem;
  @media screen and(min-width: map-get($grid-breakpoints, "md")) {
    margin-bottom: 12rem;
    padding-top: 1rem;
  }

  @media screen and(min-width: map-get($grid-breakpoints, "lg")) {
    margin-bottom: 18rem;
    padding-top: 4rem;
  }

  div {
    position: relative;
    transition: all 1s ease-out;
  }

  img {
    width: 100%;
    pointer-events: none;
    @media screen and(min-width: map-get($grid-breakpoints, "md")) {

      pointer-events: auto;
      cursor: pointer;

      &.centervisual {
        position: absolute;
        top: 50%;
        transform: translateY(-25%);
      }
      &.centerperfect {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &.bottom {
        position: absolute;
        bottom: 0;
      }

      opacity: 0.98;


      &:hover {
        opacity: 1;
        filter: blur(0);
      }
    }
  }

  button.continue {
    display: none;

    @media screen and(min-width: map-get($grid-breakpoints, "md")) {
      transition: all .125s ease-out;
      transform: translateY(.98rem);
      user-select: none;
      display: block;
      width: 32px;
      height: 32px;
      outline: none;
      border: solid 2px $gray-400;
      margin: .5rem auto 0 auto;
      border-radius: 50%;
      font-size: 1.3rem;
      color: $gray-400;
      background-color: transparent;
      font-weight: lighter;
      line-height: 0;

      &.last {
        transform: rotate(180deg);
      }

      &:hover, &:focus {
        color: $black;
        border-color: $black;
      }
    }
  }

  .title {

    @media screen and(min-width: map-get($grid-breakpoints, "md")) {
      padding-top: 3rem;
      padding-left: 12rem;
    }

    a {
      color: $black;
      font-style: italic;

      &:hover, &:focus {
        color: $gray-100;
        background-color: $red;

        text-decoration: none;
      }

      @media (prefers-color-scheme: dark) {
        color: $gray-100;

        &:hover, &:focus {
          color: $gray-900;
          background-color: $red;

          text-decoration: none;
        }
      }
    }


  }
}
