article {
  max-width: 900px;
  font-size: 1.1rem;

  h1 {
    margin-bottom: 1.5rem;
    font-size: 1.7rem;
    color: $red;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
  }
}
