html {
  scroll-behavior: smooth;
}

body {
  background-color: $gray-100;
  color: $black;

  @media (prefers-color-scheme: dark) {
    background-color: $gray-900;
    color: $gray-100;
  }

  &.locked {
    overflow: hidden;
  }
}

.wrapper {
  max-width: 1106px;
  margin: 0 auto;
}
