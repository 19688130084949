$gray-100: #ececec;
$gray-200: #e6e5e6;

$gray-900: #1c2427;

$red: #cd6e72;

$black: rgba(0, 0, 0, 0.75);

$font-family-base: "Times New Roman", Times, serif;
