.header {
  margin-bottom: 6rem;

  h3 {
    a {
      color: $black;

      &:hover, &:focus {
        color: $gray-100;
        background-color: $black;
        text-decoration: none;
      }

      @media (prefers-color-scheme: dark) {
        color: $gray-100;

        &:hover, &:focus {
          color: $gray-900;
          background-color: $gray-100;
          text-decoration: none;
        }
      }
    }
  }

  .claim {
    font-style: italic;
  }
}
