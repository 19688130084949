button.nav {
  transition: all .125s ease-out;
  user-select: none;
  display: inline-block;
  width: 25px;
  height: 25px;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: $red;
  font-weight: lighter;
  line-height: 0;


  &:hover, &:focus {
    transform: scale(1.1);
  }
}

.navigation {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  z-index: 100;

  overflow: hidden;

  &.disabled {
    display: none;
    pointer-events: none;
  }

  background-color: $red;

  ul {
    display: block;
    margin: 0 auto;
    padding-top: 10rem;
    list-style: none;
    padding-left: 0;

    li {
      text-align: center;

      a {
        font-size: 2.8rem;
        color: $gray-100;

        &:hover, &:focus {
          text-decoration: none;
          color: $red;
          background-color: $gray-100;
        }
      }
    }
  }
}
