@import "bootstrap-overrides";
@import "~bootstrap/scss/bootstrap";

@import "document";
@import "header";
@import "navigation";
@import "element";
@import "content";
@import "overlays";

.mygrid {
  .col-1 {
    &:nth-of-type(1) {
      background-color: $blue;
    }

    &:nth-of-type(2) {
      background-color: $indigo;
    }

    &:nth-of-type(3) {
      background-color: $purple;
    }

    &:nth-of-type(4) {
      background-color: $pink;
    }

    &:nth-of-type(5) {
      background-color: $red;
    }

    &:nth-of-type(6) {
      background-color: $orange;
    }

    &:nth-of-type(7) {
      background-color: $yellow;
    }

    &:nth-of-type(8) {
      background-color: $green;
    }

    &:nth-of-type(9) {
      background-color: $teal;
    }

    &:nth-of-type(10) {
      background-color: $cyan;
    }

    &:nth-of-type(11) {
      background-color: $white;
    }

    &:nth-of-type(12) {
      background-color: $black;
      color: $white;
    }
  }
}
