@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0.9;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

img[src="#"], img[src=""] {
  opacity: 0;
  pointer-events: none;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  z-index: 100;

  overflow: hidden;

  &.disabled {
    display: none;
    pointer-events: none;
  }

  // DONT CHANGE THE UNIT (s) Its used in js code for calculations ;-)
  $transitionDuration: .3s;

  div.canvas {
    transition: all $transitionDuration ease-in-out;
  }

  img {
    transition: filter $transitionDuration ease-in-out, transform $transitionDuration ease-in-out;
  }

  img, div.canvas {
    background-color: rgba(0, 0, 0, 1);
    overflow: hidden;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 90%;

    &.loading {
      filter: blur(9px);
      transform: translateX(-50%) translateY(-50%) scale(6);

      animation-name: pulse;
      animation-duration: $transitionDuration * 4;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
}


.controls {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  height: 60px;
  padding-top: 1.2rem;

  text-align: center;

}

.control {
  transition: all .25s ease-out;
  display: inline-block;
  width: 32px;
  height: 32px;
  border: none;
  outline: none;

  &:focus {
    outline: none !important;
  }

  background-color: rgba(255, 255, 255, 0.25);
  color: $red;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  border-radius: 50%;

  &:hover {
    background-color: rgba(205, 110, 114, 0.8);
    opacity: .75;
  }

  &.prev {
    background-image: url(../controls/arrow.png);
    transform: rotate(180deg);
    background-position: 3px center;

  }

  &.leave {
    background-image: url(../controls/close.png);
    transform: scale(.5);
    margin: 0 2.5rem;
  }

  &.next {
    background-image: url(../controls/arrow.png);
    background-position: 3px center;

  }
}
